import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"
import LayoutContext from "../../../components/LayoutContext"
import Image from "../../../components/Image"
import Curve1 from "../../../svgs/shapes/curves/curve-1.svg"

const Auswahl = () => {
  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      hide: true,
      stopTrack: true,
    }))
    return function unmount() {
      setLayout(state => ({
        ...state,
        hide: false,
        stopTrack: false,
      }))
    }
  }, [])

  return (
    <>
      <Helmet>
        <script src="/iframeResizer.contentWindow.min.js" />
      </Helmet>
      <div className="card shadow-light-lg">
        <div
          className="card-img-top"
          style={{
            maxHeight: "300px",
            overflow: "hidden",
          }}
        >
          <Image
            src="/assets/custom/AdobeStock_251453634.jpg"
            className="img-fluid"
            alt="Kino eingeschlafen"
          />
          <div
            style={{ position: "absolute", bottom: "10px", width: "100%" }}
            className="mx-auto text-center"
          >
            <Image
              src="/assets/logo/maklerzentrale_white.png"
              loadFrom="logo"
              style={{ maxWidth: "310px" }}
              // className="mx-auto"
              alt="Maklerzentrale"
            />
          </div>
        </div>

        <div className="position-relative">
          <div className="shape shape-fluid-x shape-bottom text-white">
            <Curve1 />
          </div>
        </div>

        <div className="card-body py-4">
          <h3>Zieht sich der Immobilienkauf in die Länge?</h3>

          <p className="mb-0 text-muted">
            Kommen Sie am besten gleich zu unseren Immobilienmaklern.
          </p>
        </div>
        <div className="card-meta">
          {/*<hr className="card-meta-divider" />*/}
          <a
            href="/de/immobilienmakler/"
            target="_blank"
            className="btn btn-xs btn-primary me-2 mb-0"
          >
            Makler finden
          </a>

          <a
            href="/de/immobilienbewertung"
            target="_blank"
            className="btn btn-xs btn-outline-primary mb-0 ms-auto"
          >
            Immobilie bewerten
          </a>
        </div>
      </div>
    </>
  )
}

export default Auswahl
